<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label><i class="fa fa-wallet mr-1" /> Carteira / Conta</label>

        <v-select
          :options="carteiraOpcoes"
          multiple
          v-model="carteiras"
          label="nome"
        />
        <hr />
        <b-form-group v-slot="{ ariaDescribedby3 }">
          <b-form-radio-group
            id="radio-group-3"
            v-model="nivelExibicao"
            :options="[
              { text: 'Resumido', value: 'Resumido' },
              { text: 'Detalhado', value: 'Detalhado' },
            ]"
            :aria-describedby="ariaDescribedby3"
            name="radio-options3"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-6 text-left pl-3">
        <b-form-group label="Consolidação" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-1"
            v-model="consolidado"
            :options="[
              { text: 'Todos', value: '' },
              { text: 'Consolidado', value: 'S' },
              { text: 'Não Consolidado', value: 'N' },
            ]"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group label="Exibição" v-slot="{ ariaDescribedby2 }">
          <b-form-radio-group
            id="radio-group-2"
            v-model="exibicao"
            :options="[
              { text: 'Por Carteira', value: 'carteira' },
              { text: 'Por Historico', value: 'historico' },
              { text: 'Plano Contas', value: 'plano_contas' },
            ]"
            :aria-describedby="ariaDescribedby2"
            name="radio-options2"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div class="text-center p-4" v-if="loading">
      <b-spinner /> carrengando...
    </div>

    <div v-if="(exibicao == 'carteira' && resumo)">
      <table
        class="table text-center tbMobile table-hover"
        id="toggled-element"
        v-if="!$store.state.isMobile"
      >
        <thead>
          <tr>
            <th></th>
            <th>
              Saldo Anterior <br /><small
                ><small>*até o periodo inicial</small></small
              >
            </th>
            <th>
              Movimentado<br /><small><small>*no periodo</small></small>
            </th>
            <th>
              Saldo<br /><small><small>*até o periodo final</small></small>
            </th>
            <th>
              Saldo Atual<br /><small><small>*real</small></small>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-primary text-white">
            <th class="text-left pl-1">TOTAL</th>
            <th>{{ resumo.saldo_anterior | currency }}</th>
            <th>{{ resumo.movimentado | currency }}</th>
            <th>{{ resumo.saldo | currency }}</th>
            <th>
              <b>{{ resumo.saldo_atual | currency }}</b>
            </th>
          </tr>
          <template v-for="cart in resumo.carteiras">
            <tr :key="`movcart_${cart.carteira}`" class="font-size:12px;">
              <td class="text-left pl-3">{{ cart.carteira }}</td>
              <td>{{ cart.saldo_anterior | currency }}</td>
              <td>{{ cart.movimentado | currency }}</td>
              <td>{{ cart.saldo | currency }}</td>
              <th>
                <b>{{ cart.saldo_atual | currency }}</b>
              </th>
            </tr>
            <template v-if="nivelExibicao == 'Detalhado'">
              <tr
                v-for="hist in cart.historicos"
                :key="`movcarthist_${hist.historico}`"
                :class="`font-size:10px; text-${
                  hist.tipo == 1 ? 'success' : 'danger'
                }`"
              >
                <td :class="`text-left pl-5`">{{ hist.historico }}</td>
                <td>{{ hist.saldo_anterior | currency }}</td>
                <td>{{ hist.movimentado | currency }}</td>
                <td>{{ hist.saldo | currency }}</td>
                <th>
                  <b>{{ hist.saldo_atual | currency }}</b>
                </th>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <div v-if="$store.state.isMobile" class="text-left">
        <div class="row">
          <div class="col-12">
            <b-card title="Totalizador">
              <b>Saldo Anterior: </b> R$ {{ resumo.saldo_anteior | currency }}
              <br />
              <b class="ml-3"
                >Movimentado <small><small>(*dentro do periodo)</small></small
                >:
              </b>
              R$ {{ resumo.movimentado | currency }} <br />
              <b class="ml-3"
                >Saldo <small><small>(*periodo final)</small></small
                >:
              </b>
              R$ {{ resumo.saldo | currency }} <br />
              <b>Saldo Atual: </b> R$ {{ resumo.saldo_atual | currency }}
            </b-card>
            <br />
          </div>
          <div
            class="col-12"
            v-for="cart in resumo.carteiras"
            :key="`carteiraMobi_${cart.carteira}`"
          >
            <b-card :title="cart.carteira">
              <b>Saldo Anterior: </b> R$ {{ cart.saldo_anteior | currency }}
              <br />
              <b class="ml-3"
                >Movimentado <small><small>(*dentro do periodo)</small></small
                >:
              </b>
              R$ {{ cart.movimentado | currency }} <br />
              <b class="ml-3"
                >Saldo <small><small>(*periodo final)</small></small
                >:
              </b>
              R$ {{ cart.saldo | currency }} <br />
              <b>Saldo Atual: </b> R$ {{ cart.saldo_atual | currency }}
            </b-card>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div v-if="(exibicao == 'historico' && resumo)">
      <table
        class="table text-center tbMobile"
        id="toggled-element"
        v-if="!$store.state.isMobile"
      >
        <thead>
          <tr>
            <th></th>
            <th>
              Saldo Anterior <br /><small
                ><small>*até o periodo inicial</small></small
              >
            </th>
            <th>
              Movimentado<br /><small><small>*no periodo</small></small>
            </th>
            <th>
              Saldo<br /><small><small>*até o periodo final</small></small>
            </th>
            <th>
              Saldo Atual<br /><small><small>*real</small></small>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-primary text-white">
            <th class="text-left pl-1">TOTAL</th>
            <th>{{ resumo.saldo_anterior | currency }}</th>
            <th>{{ resumo.movimentado | currency }}</th>
            <th>{{ resumo.saldo | currency }}</th>
            <th>
              <b>{{ resumo.saldo_atual | currency }}</b>
            </th>
          </tr>
          <template
            v-for="tp in resumo.historicos
              .sort((a, b) => b.tipo - a.tipo)
              .reduce((ret, vl) => {
                if (!ret.some((x) => x == vl.tipo)) ret.push(vl.tipo);
                return ret;
              }, [])"
          >
            <tr
              :key="`tpHistResBanc_${tp}`"
              class="text-white"
              :class="tp == 1 ? 'bg-success' : 'bg-danger'"
            >
              <th class="text-left pl-2">
                {{ tp == 1 ? "Receitas" : "Despesas" }}
              </th>
              <th>
                {{
                  resumo.historicos
                    .filter((x) => x.tipo == tp)
                    .reduce((r, v) => {
                      r += v.saldo_anterior;
                      return r;
                    }, 0) | currency
                }}
              </th>
              <th>
                {{
                  resumo.historicos
                    .filter((x) => x.tipo == tp)
                    .reduce((r, v) => {
                      r += v.movimentado;
                      return r;
                    }, 0) | currency
                }}
              </th>
              <th>
                {{
                  resumo.historicos
                    .filter((x) => x.tipo == tp)
                    .reduce((r, v) => {
                      r += v.saldo;
                      return r;
                    }, 0) | currency
                }}
              </th>
              <th>
                {{
                  resumo.historicos
                    .filter((x) => x.tipo == tp)
                    .reduce((r, v) => {
                      r += v.saldo_atual;
                      return r;
                    }, 0) | currency
                }}
              </th>
            </tr>
            <template
              v-for="hist in resumo.historicos
                .filter((x) => x.tipo == tp)
                .sort((a, b) => b.tipo - a.tipo)"
            >
              <tr
                :key="`movcartHist_${hist.historico}`"
                class="font-size:12px;"
                :class="`font-size:10px; text-${
                  hist.tipo == 1 ? 'success' : 'danger'
                }`"
              >
                <td class="text-left pl-4">{{ hist.historico }}</td>
                <td>{{ hist.saldo_anterior | currency }}</td>
                <td>{{ hist.movimentado | currency }}</td>
                <td>{{ hist.saldo | currency }}</td>
                <th>
                  <b>{{ hist.saldo_atual | currency }}</b>
                </th>
              </tr>
              <template v-if="nivelExibicao == 'Detalhado'">
                <tr
                  v-for="cart in hist.carteiras"
                  :key="`movcarthistCart_${cart.carteira}`"
                >
                  <td :class="`text-left pl-5`">{{ cart.carteira }}</td>
                  <td>{{ cart.saldo_anterior | currency }}</td>
                  <td>{{ cart.movimentado | currency }}</td>
                  <td>{{ cart.saldo | currency }}</td>
                  <th>
                    <b>{{ cart.saldo_atual | currency }}</b>
                  </th>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
      <div v-if="$store.state.isMobile" class="text-left">
        <div class="row">
          <div class="col-12">
            <b-card title="Totalizador">
              <b>Saldo Anterior: </b> R$ {{ resumo.saldo_anteior | currency }}
              <br />
              <b class="ml-3"
                >Movimentado <small><small>(*dentro do periodo)</small></small
                >:
              </b>
              R$ {{ resumo.movimentado | currency }} <br />
              <b class="ml-3"
                >Saldo <small><small>(*periodo final)</small></small
                >:
              </b>
              R$ {{ resumo.saldo | currency }} <br />
              <b>Saldo Atual: </b> R$ {{ resumo.saldo_atual | currency }}
            </b-card>
            <br />
          </div>
          <div
            class="col-12"
            v-for="hist in resumo.historicos"
            :key="`carteiraMobiHist_${hist.historico}`"
          >
            <b-card :title="hist.historico">
              <b>Saldo Anterior: </b> R$ {{ hist.saldo_anteior | currency }}
              <br />
              <b class="ml-3"
                >Movimentado <small><small>(*dentro do periodo)</small></small
                >:
              </b>
              R$ {{ hist.movimentado | currency }} <br />
              <b class="ml-3"
                >Saldo <small><small>(*periodo final)</small></small
                >:
              </b>
              R$ {{ hist.saldo | currency }} <br />
              <b>Saldo Atual: </b> R$ {{ hist.saldo_atual | currency }}
            </b-card>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div v-if="(exibicao == 'plano_contas' && resumo && resumo.planoContas)">
      <table
        class="table text-center tbMobile table-hover"
        id="toggled-element"
        v-if="!$store.state.isMobile"
      >
        <thead>
          <tr>
            <th></th>
            <th>
              Saldo Anterior <br /><small
                ><small>*até o periodo inicial</small></small
              >
            </th>
            <th>
              Movimentado<br /><small><small>*no periodo</small></small>
            </th>
            <th>
              Saldo<br /><small><small>*até o periodo final</small></small>
            </th>
            <th>
              Saldo Atual<br /><small><small>*real</small></small>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-primary text-white">
            <th class="text-left pl-1">TOTAL</th>
            <th>{{ resumo.saldo_anterior | currency }}</th>
            <th>{{ resumo.movimentado | currency }}</th>
            <th>{{ resumo.saldo | currency }}</th>
            <th>
              <b>{{ resumo.saldo_atual | currency }}</b>
            </th>
          </tr>
          <template
            v-for="tp in resumo.planoContas
              .sort((a, b) => b.tipo - a.tipo)
              .reduce((ret, vl) => {
                if (!ret.some((x) => x == vl.tipo)) ret.push(vl.tipo);
                return ret;
              }, [])"
          >
            <tr
              :key="`tpPLCONTAResBanc_${tp}`"
              class="text-white"
              :class="tp == 1 ? 'bg-success' : tp == 0?'bg-danger':'bg-info'"
            >
              <th class="text-left pl-2">
                {{ tp == 1 ? "Receitas" : tp==0?"Despesas":"Sem plano de Contas" }}
              </th>
              <th>
                {{
                  resumo.planoContas
                    .filter((x) => x.tipo == tp)
                    .reduce((r, v) => {
                      r += v.saldo_anterior;
                      return r;
                    }, 0) | currency
                }}
              </th>
              <th>
                {{
                  resumo.planoContas
                    .filter((x) => x.tipo == tp)
                    .reduce((r, v) => {
                      r += v.movimentado;
                      return r;
                    }, 0) | currency
                }}
              </th>
              <th>
                {{
                  resumo.planoContas
                    .filter((x) => x.tipo == tp)
                    .reduce((r, v) => {
                      r += v.saldo;
                      return r;
                    }, 0) | currency
                }}
              </th>
              <th>
                {{
                  resumo.planoContas
                    .filter((x) => x.tipo == tp)
                    .reduce((r, v) => {
                      r += v.saldo_atual;
                      return r;
                    }, 0) | currency
                }}
              </th>
            </tr>
            <template
              v-for="gp in resumo.planoContas
                .filter((x) => x.tipo == tp && x.planoContasGrupo)
                .reduce((r, v) => {
                  if (!r.some((x) => x == v.planoContasGrupo)) {
                    r.push(v.planoContasGrupo);
                  }
                  return r;
                }, [])"
            >
              <tr :key="`HistPlGrp${gp}_${tp}`" style="background-color: #ddd">
                <th class="text-left pl-3">{{ gp }}</th>
                <th>
                  {{
                    resumo.planoContas
                      .filter((x) => x.tipo == tp && x.planoContasGrupo == gp)
                      .reduce((r, v) => {
                        r += v.saldo_anterior;
                        return r;
                      }, 0) | currency
                  }}
                </th>
                <th>
                  {{
                    resumo.planoContas
                      .filter((x) => x.tipo == tp && x.planoContasGrupo == gp)
                      .reduce((r, v) => {
                        r += v.movimentado;
                        return r;
                      }, 0) | currency
                  }}
                </th>
                <th>
                  {{
                    resumo.planoContas
                      .filter((x) => x.tipo == tp && x.planoContasGrupo == gp)
                      .reduce((r, v) => {
                        r += v.saldo;
                        return r;
                      }, 0) | currency
                  }}
                </th>
                <th>
                  {{
                    resumo.planoContas
                      .filter((x) => x.tipo == tp && x.planoContasGrupo == gp)
                      .reduce((r, v) => {
                        r += v.saldo_atual;
                        return r;
                      }, 0) | currency
                  }}
                </th>
              </tr>
              <template
                v-for="sgp in resumo.planoContas
                  .filter((x) => x.tipo == tp && x.planoContasGrupo == gp)
                  .reduce((r, v) => {
                    if (!r.some((x) => x == v.planoContasSubGrupo)) {
                      r.push(v.planoContasSubGrupo);
                    }
                    return r;
                  }, [])"
              >
                <tr :key="`HistPlGrp${sgp}_${gp}_${tp}`" style="background-color: #f8f8f8">
                  <th class="text-left pl-4">{{ sgp }}</th>
                  <th>
                    {{
                      resumo.planoContas
                        .filter(
                          (x) =>
                            x.tipo == tp && x.planoContasGrupo == gp && x.planoContasSubGrupo == sgp
                        )
                        .reduce((r, v) => {
                          r += v.saldo_anterior;
                          return r;
                        }, 0) | currency
                    }}
                  </th>
                  <th>
             
                    {{
                      resumo.planoContas
                        .filter(
                          (x) =>
                            x.tipo == tp && x.planoContasGrupo == gp && x.planoContasSubGrupo == sgp
                        )
                        .reduce((r, v) => {
                          r += v.movimentado;
                          return r;
                        }, 0) | currency
                    }}
                  </th>
                  <th>
                    {{
                      resumo.planoContas
                        .filter(
                          (x) =>
                            x.tipo == tp &&
                            x.planoContasGrupo == gp &&
                            x.planoContasSubGrupo == sgp
                        )
                        .reduce((r, v) => {
                          r += v.saldo;
                          return r;
                        }, 0) | currency
                    }}
                  </th>
                  <th>
                     
                    {{
                      resumo.planoContas
                        .filter(
                          (x) =>
                            x.tipo == tp &&
                            x.planoContasGrupo == gp &&
                            x.planoContasSubGrupo == sgp
                        )
                        .reduce((r, v) => {
                          r += v.saldo_atual;
                          return r;
                        }, 0) | currency
                    }}
                  </th>
                </tr>
                <template
                  v-for="pl in resumo.planoContas
                    .filter((x) => x.tipo == tp && x.planoContasGrupo == gp && x.planoContasSubGrupo == sgp)
                    .reduce((r, v) => {
                      if (!r.some((x) => x == v.planoContas)) {
                        r.push(v.PlanoContas);
                      }
                      return r;
                    }, [])"
                >
                  <tr :key="`HistPlGrp${sgp}_${gp}_${tp}_${pl}`"  style="font-size:9px">
                    <td class="text-left pl-5 pt-1 pb-1">{{ pl }}</td>
                    <td class="pt-1 pb-1">
                      {{
                        resumo.planoContas
                          .filter(
                            (x) =>
                              x.tipo == tp &&
                              x.planoContasGrupo == gp &&
                              x.planoContasSubGrupo == sgp &&
                              x.PlanoContas == pl
                          )
                          .reduce((r, v) => {
                            r += v.saldo_anterior;
                            return r;
                          }, 0) | currency
                      }}
                    </td>
                    <td class="pt-1 pb-1">
                      {{
                        resumo.planoContas
                          .filter(
                            (x) =>
                              x.tipo == tp &&
                              x.planoContasGrupo == gp &&
                              x.planoContasSubGrupo == sgp&&
                              x.PlanoContas == pl
                          )
                          .reduce((r, v) => {
                            r += v.movimentado;
                            return r;
                          }, 0) | currency
                      }}
                    </td>
                    <td class="pt-1 pb-1">
                      {{
                        resumo.planoContas
                          .filter(
                            (x) =>
                              x.tipo == tp &&
                              x.planoContasGrupo == gp &&
                              x.planoContasSubGrupo == sgp &&
                              x.PlanoContas == pl
                          )
                          .reduce((r, v) => {
                            r += v.saldo;
                            return r;
                          }, 0) | currency
                      }}
                    </td>
                    <td class="pt-1 pb-1">
                      {{
                        resumo.planoContas
                          .filter(
                            (x) =>
                              x.tipo == tp &&
                              x.planoContasGrupo == gp &&
                              x.planoContasSubGrupo == sgp&&
                              x.PlanoContas == pl
                          )
                          .reduce((r, v) => {
                            r += v.saldo_atual;
                            return r;
                          }, 0) | currency
                      }}
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <b-modal
      size="lg"
      id="modal-extrato-resumo"
      v-if="extrato"
      :title="extrato.titulo"
      hide-footer
    >
      {{ extrato }}
    </b-modal>
  </div>
</template>

<script>
import BancoMovimentoLib from "../../../libs/BancoMovimentoLib";
// import BancoMovimentoLib from "../../../libs/BancoMovimentoLib";
import CarteirasLib from "../../../libs/Carteiras";
import moment from "moment";
export default {
  components: {},
  props: {
    empresas: Array,
    data: Object,
    tipoResumo: {
      type: String,
      default: "carteira",
    },
  },
  mounted() {
    this.carregarCarteiras();
    this.carregar();
  },
  data() {
    return {
      carteiraOpcoes: [],
      carteiras: [],
      resumo: [],
      loading: false,
      consolidado: "S",
      exibicao: "plano_contas",
      extrato: null,
      nivelExibicao: "Resumido",
    };
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    carteiras: function () {
      this.carregar();
    },
    consolidado: function () {
      this.carregar();
    },
  },
  computed: {},
  methods: {
    openExtrato(extrato, titulo) {
      this.$bvModal.show("modal-extrato-resumo");
      this.extrato = {
        extrato,
        titulo,
      };
    },
    async carregarCarteiras() {
      this.carteiraOpcoes = (await CarteirasLib.getCareteiras()).data;
    },
    async carregar() {
      this.resumo = false;
      this.loading = true;
      this.$forceUpdate();
      await this.$nextTick();
      try {
        let carteiras = this.carteiras.map((it) => it.id);
        let filter = {
          dt_i: moment(this.data.start).format("YYYY-MM-DD"),
          dt_f: moment(this.data.end).format("YYYY-MM-DD"),
          carteiras,
          consolidado: this.consolidado,
        };
        this.resumo = [];
        console.log("start busca", filter);
        let res = await BancoMovimentoLib.getResumoGeral(filter);
        if (res && res.success) {
          this.resumo = res.data;
        }
        console.log(res);
      } catch (error) {
        console.log("ERROR ON GETRESMO", error);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
